import React from 'react'
import Head from 'next/head'
import { HOST_BASE_URL } from 'infra/constants'

export const Seo: React.FC<{
  title?: string
  description?: string
  image?: string
  url?: string
}> = ({
  title = '그룹바이 | 스타트업 채용 플랫폼',
  description = '검증된 스타트업 채용은 그룹바이에서 유망한 시드, 프리A, 시리즈A의 투자정보, 소개영상, 사수여부, 조직문화 등 자세한 정보를 채용 공고에서 확인하세요.',
  image = 'https://groupby-public-image.s3.ap-northeast-2.amazonaws.com/biz/Frame+3600.png',
  url = HOST_BASE_URL,
}) => (
  <Head>
    {title && (
      <>
        <title>{title}</title>
        <meta property='og:title' content={title} />
      </>
    )}
    {description && (
      <>
        <meta name='description' content={description} />
        <meta property='og:description' content={description} />
      </>
    )}
    {image && <meta property='og:image' content={encodeURI(image)} />}
    {url && <meta property='og:url' content={url} />}
  </Head>
)
