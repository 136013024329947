import React from 'react'
import styled from 'styled-components'
import Image from 'next/legacy/image'
import { Row } from 'components/common/layout'
import { useIsDesktop } from 'utils/hooks'

export const ScoutLandingFundingDescBelt: React.FC = () => {
  const isDesktop = useIsDesktop()
  const imageWidth = isDesktop ? 1267 : 750
  const imageHeight = isDesktop ? 52 : 30
  return (
    <Main>
      <Container>
        <FoundingImage
          src='/funding-belt.svg'
          alt='/funding-belt.svg'
          width={imageWidth}
          height={imageHeight}
        />
        <FoundingImage
          src='/funding-belt.svg'
          alt='/funding-belt.svg'
          width={imageWidth}
          height={imageHeight}
        />
        <FoundingImage
          src='/funding-belt.svg'
          alt='/funding-belt.svg'
          width={imageWidth}
          height={imageHeight}
        />
      </Container>
    </Main>
  )
}

const Main = styled(Row)`
  height: 75px;
  position: relative;
  overflow: hidden;
  border: 1px solid ${(p) => p.theme.color.line};
`

const Container = styled(Row)`
  position: absolute;
  left: 0px;
  gap: 80px;
  @keyframes fundingBeltSlide {
    0% {
      left: 0px;
    }
    100% {
      left: -1267px;
    }
  }
  animation: fundingBeltSlide 50000ms infinite linear;
`

const FoundingImage = styled(Image).attrs<{ isDesktop: boolean }>({
  priority: true,
  layout: 'fixed',
})``
