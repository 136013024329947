import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Column } from 'components/common/layout'
import { BoldText } from 'components/common/text'

const data = [
  '좋은 인재들과 팀 문화를 만들며 협업하고 싶다면',
  '전문성을 기르며 기술적 성장을 하고 싶다면',
  '자유로운 분위기에서 수평적인 의사결정을 하고싶다면',
  '다양한 규모의 팀을 경험해보고 싶다면',
  '새로운 도메인을 탐구해보고 싶다면',
  '나의 한계를 넘어 도전하고 싶다면',
]

const timer = 4000

export const ScoutLandingSignboard: React.FC = () => {
  const [index, setIndex] = useState<number>(0)

  useEffect(() => {
    const timerId = setInterval(
      () => setIndex((i) => (i === data.length - 1 ? 0 : i + 1)),
      timer,
    )
    return () => clearInterval(timerId)
  }, [])

  return (
    <Main>
      <MainText>{data[index]}</MainText>
    </Main>
  )
}

const MainText = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
  font-size: 48px;
  color: ${(p) => p.theme.color.white1};
  @keyframes signBoardFadeInOut {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  animation: signBoardFadeInOut ${timer}ms infinite ease-in-out;
`

const Main = styled(Column)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    height: 150px;
  }
  height: 222px;
  align-items: center;
  background: ${(p) => p.theme.color.linear};
`
