import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { H4Text, H5Text } from 'components/common/text'
import { ScoutLandingCtaBtn } from 'components/scouts/landing/cta-button'
import { LinearText } from 'components/scouts/landing/text'
import { useIsDesktop, useObserver } from 'utils/hooks'

export const ScoutLandingIntro: React.FC<{
  setIsButtonVisible: (isVisible: boolean) => void
}> = ({ setIsButtonVisible }) => {
  const isDesktop = useIsDesktop()
  const observingTarget = useObserver((entries) => {
    entries[0].isIntersecting
      ? setIsButtonVisible(true)
      : setIsButtonVisible(false)
  })
  return (
    <Main>
      <Container isDesktop={isDesktop}>
        <Column style={{ gap: 24 }}>
          <MainText>{`검증된 스타트업에게\n스카우트 제안 받으세요`}</MainText>
          <SubText>
            {`VC가 투자한 성공 보장 스타트업에 합류해보세요.\n매달 300명이 넘는 인재가 `}
            <br />
            {`스카우트 제안을 받고 있어요.`}
          </SubText>
        </Column>
        <ScoutLandingCtaBtn observingTarget={observingTarget} />
      </Container>
      <Sub2Text>
        아래 투자사에서 투자받은 스타트업에 지금 합류해보세요!
      </Sub2Text>
    </Main>
  )
}

const SubText = styled(H4Text)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    br {
      display: none;
    }
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
  line-height: 1.5;
  white-space: pre-wrap;
  color: ${(p) => p.theme.color.black4};
`

const Sub2Text = styled(H5Text)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 14px;
  }
  text-align: center;
  padding: 16px;
  color: ${(p) => p.theme.color.black4};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  position: absolute;
  bottom: 0px;
  @keyframes introSub2TextFadeIn {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: introSub2TextFadeIn 2000ms 1;
`

const Container = styled(Column)<{ isDesktop: boolean }>`
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 0 16px;
    width: 100%;
    align-items: flex-start;
    text-align: start;
  }
  top: ${(p) => (p.isDesktop ? 200 : 170)}px;
  flex: 1;
  align-items: center;
  text-align: center;
  gap: 48px;
  position: absolute;
  @keyframes introMainTextFadeIn {
    0% {
      top: ${(p) => (p.isDesktop ? 400 : 300)}px;
      opacity: 0;
    }
    100% {
      top: ${(p) => (p.isDesktop ? 200 : 170)}px;
      opacity: 1;
    }
  }
  animation: introMainTextFadeIn 1000ms 1;
`

const MainText = styled(LinearText)`
  line-height: 1.3;
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 32px;
  }
  font-size: 66px;
  white-space: pre-wrap;
`

const Main = styled(Row)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    height: 615px;
  }
  position: relative;
  height: 824px;
  justify-content: center;
  background: linear-gradient(85deg, #ffffff 2.65%, #e0f8ee 95.98%);
`
