import React from 'react'
import styled from 'styled-components'
import { Row } from 'components/common/layout'
import Image from 'next/legacy/image'

export const ScoutLandingVcBelt: React.FC = () => (
  <Main>
    <Container>
      <VcImage src='/vc-belt.svg' />
      <VcImage src='/vc-belt.svg' />
      <VcImage src='/vc-belt.svg' />
    </Container>
  </Main>
)

const Main = styled(Row)`
  height: 75px;
  position: relative;
  overflow: hidden;
`

const Container = styled(Row)`
  position: absolute;
  left: 0px;
  gap: 40px;
  @keyframes vcBeltSlider {
    0% {
      left: 0px;
    }
    100% {
      left: -1440px;
    }
  }
  animation: vcBeltSlider 50000ms infinite linear;
`

const VcImage = styled(Image).attrs({
  height: 75,
  width: 1440,
  priority: true,
  layout: 'fixed',
  alt: 'vc-logo',
})``
