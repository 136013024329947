import React from 'react'
import styled from 'styled-components'
import { Column } from 'components/common/layout'
import { BaseText, BoldText, SemiBoldText } from 'components/common/text'
import { SlickSlider } from 'components/common/slider'
import Image from 'next/legacy/image'
import { MyLink } from 'components/common/link'

interface DevReview {
  id: number
  icon: string
  desc: string
  writer: string
  interviewLink: string
  title: string
  subTitle: string
}

const DEV_REVIEW_DATA: DevReview[] = [
  {
    id: 1,
    icon: '/tain-ai.svg',
    desc: '한 명도 편하게 월급을 받아가시려는 분이 없는\n모두가 열심히 일하는 분위기의 회사에 매우 만족하고 있습니다.\n슬랙을 통한 팀간의 소통과 기록이 매우 활발하여\n회사 전체 방향을 쉽게 팔로우업 할 수 있습니다.',
    writer: '프론트엔드 개발자 안*진',
    interviewLink: 'https://youtu.be/OgGbqN4r5aE?si=KZFmC28jcoCqfq1Y',
    title: '글로벌에서 매출 1위를 찍은',
    subTitle: 'AI 캐릭터 채팅 서비스 “타인에이아이”',
  },
  {
    id: 2,
    icon: '/heum-logo.svg',
    desc: '서로에 대한 신뢰와 존중, 자유로운 의사 표현, 열정적인 구성원으로 매우 이상적인 조직문화라고 생각하고 근무 만족도가 높아요. 워라밸도 강제적이거나 비효율적인 야근이 없어서 만족하고 있고, 다양한 프로젝트를 진행하게 되면서 성장기회도 많이 열려 있습니다.',
    writer: '10년차 그로스마케터 박*석',
    interviewLink:
      'https://groupby.careers/%ea%b7%b8%ed%84%b0%eb%b7%b0-%ea%b0%80%ec%9e%a5-%eb%a7%8c%ec%a1%b1%eb%8f%84-%eb%86%92%ec%9d%80-%ec%9d%b4%ec%a7%81%ed%94%8c%eb%9e%ab%ed%8f%bc-%ea%b7%b8%eb%a3%b9%eb%b0%94%ec%9d%b4-%ec%b6%94%ec%b2%9c/',
    title: '100만 사업자가 사용하는',
    subTitle: 'B2B 금융 초격차 AI스타트업 “혜움”',
  },
  {
    id: 3,
    icon: '/tmr-logo.svg',
    desc: '그룹바이에 이력서를 올리니까 일주일만에 제안을 두번이나 받고 현재 근무하는 티엠알파운더스와 미팅도 하게 되었어요. 비즈니스 성장속도도 매우 빠르고 코파운더의 열정과 에너지를 보고 확신을 얻어 합류를 하였습니다. 지금도 조직문화에 매우 만족하며 근무하고 있습니다.',
    writer: '10년차 백엔드개발자 윤*선',
    interviewLink:
      'https://groupby.careers/%ea%b7%b8%ed%84%b0%eb%b7%b0-%ec%9d%bc%ec%a3%bc%ec%9d%bc-%eb%a7%8c%ec%97%90-%ec%8a%a4%ec%b9%b4%ec%9a%b0%ed%8a%b8-%ec%a0%9c%ec%95%88-2%ea%b0%9c-%eb%b0%9b%ea%b3%a0-%ec%9d%b4%ec%a7%81%ed%95%9c-%eb%b9%84/',
    title: '누적거래액 400억, 사용자 200만명',
    subTitle: '테이블오더를 혁신하는 “티엠알파운더스”',
  },
]

export const ScoutLandingStartupDescription: React.FC = () => {
  return (
    <Main>
      <HeadingText>그룹바이를 통해서 합류한</HeadingText>
      <MainText>
        검증된 스타트업은
        <br /> 이렇다고 해요
      </MainText>
      <SliderWrapper>
        <SlickSlider settings={{ autoplay: true }}>
          {DEV_REVIEW_DATA.map((item) => (
            <Form key={item.id} data={item} />
          ))}
        </SlickSlider>
      </SliderWrapper>
    </Main>
  )
}

const Form: React.FC<{ data: DevReview }> = ({ data }) => {
  const { icon, desc, writer, interviewLink, title, subTitle } = data
  return (
    <FormContainer>
      <WriterText>{title}</WriterText>
      <DescText>{subTitle}</DescText>
      <Image src={icon} width={180} height={120} alt={icon} priority />
      <DescText>{desc}</DescText>
      <WriterText>{writer}</WriterText>
      <MyLink href={interviewLink} newTab>
        <InterviewLink>인터뷰 보기</InterviewLink>
      </MyLink>
    </FormContainer>
  )
}

const DescText = styled(BaseText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    font-size: ${(p) => p.theme.fontSize.default};
  }
  font-size: 20px;
  line-height: 1.6;
  color: ${(p) => p.theme.color.black4};
  width: 600px;
  text-align: center;
`

const WriterText = styled(SemiBoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.desc};
  }
  font-size: 20px;
  line-height: 1.5;
  margin-top: 27px;
`

const InterviewLink = styled(WriterText)`
  color: ${(p) => p.theme.color.primary};
  text-decoration: underline;
`

const FormContainer = styled(Column)`
  align-items: center;
`

const SliderWrapper = styled(Column)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    margin-top: 60px;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    .slick-prev,
    .slick-next {
      top: 65px;
    }
  }
  width: 1144px;
  .slick-active {
    z-index: 1;
  }
`

const HeadingText = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 14px;
  }
  font-size: 24px;
  line-height: 2;
  color: ${(p) => p.theme.color.primary4};
`

const MainText = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    br {
      display: none;
    }
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 32px;
    text-align: center;
  }
  font-size: 50px;
`

const Main = styled(Column)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    height: 523px;
    padding: 0 16px;
  }
  height: 698px;
  align-items: center;
`
