import React from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import Image from 'next/legacy/image'
import { BaseText, BoldText, SemiBoldText } from 'components/common/text'
import { SlickSlider } from 'components/common/slider'
import { useIsDesktop } from 'utils/hooks'

const data = [
  {
    mainText: '나에게 딱 맞는 조건의\n이직 제안만 받아보세요',
    headingText: '1. 이직 노이즈 최소화',
    descText:
      '제안 받을때마다 조건 맞추기 어려우셨죠?\n이제 조건을 등록해놓고 만족하는 이직 제안만 받아보세요.',
    icon: '/salary-slider.svg',
  },
  {
    mainText: '등록만 하면 쏟아지는\n스타트업 채용 제안',
    headingText: '2. 스타트업 자동매칭 서비스',
    descText:
      '채용 서비스에서 정보를 찾으실 때 시간 걸리셨죠?\n이제 찾지 않아도 스타트업이 알아서 찾아갑니다.',
    icon: '/startup-messages.svg',
  },
  {
    mainText: '검증된 스타트업의\n모든 정보를 볼 수 있어요',
    headingText: '3. 스타트업 전문 채용 서비스',
    descText:
      'VC에게 투자받은 성공 보장 스타트업 정보를 한눈에 보세요.\n찾을 수 없는 스타트업의 모든 정보들을 그룹바이에서 제공합니다.',
    icon: '/scout-positions.svg',
  },
  {
    mainText: '소통부터\n일정 조율까지 한번에',
    headingText: '4. 이직 올인원 프로세스',
    descText:
      '그동안 이메일, 전화로 복잡하게 소통하셨나요?\n소통부터 일정 조율까지 한번에 진행해보세요.',
    icon: '/coffee-chat.svg',
  },
]

export const ScoutLandingServiceDescription: React.FC = () => {
  return (
    <Main>
      <SliderWrapper>
        <SlickSlider settings={{ dots: true, autoplay: true }}>
          {data.map((item, index) => (
            <Form
              key={index}
              mainText={item.mainText}
              headingText={item.headingText}
              descText={item.descText}
              icon={item.icon}
            />
          ))}
        </SlickSlider>
      </SliderWrapper>
    </Main>
  )
}

const Form: React.FC<{
  mainText: string
  headingText: string
  descText: string
  icon: string
}> = ({ mainText, headingText, descText, icon }) => {
  const isDesktop = useIsDesktop()
  return (
    <FormContainer>
      <FormWrapper>
        <TextContainer>
          <TextWrapper>
            <HeadingText>{headingText}</HeadingText>
            <MainText>{mainText}</MainText>
          </TextWrapper>
          <SubText>{descText}</SubText>
        </TextContainer>
        <Image
          src={icon}
          width={isDesktop ? 420 : 280}
          height={isDesktop ? 360 : 240}
          alt={icon}
          priority
        />
      </FormWrapper>
    </FormContainer>
  )
}

const TextWrapper = styled(Column)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    gap: 8px;
  }
  gap: 16px;
`

const FormWrapper = styled(Row)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    flex-direction: column;
    gap: 40px;
  }
  gap: 83px;
`

const SliderWrapper = styled(Column)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    .slick-prev,
    .slick-next {
      top: 0;
    }
    .slick-prev {
      left: calc(100vw - 100px) !important;
    }
  }
  width: 1144px;
`

const TextContainer = styled(Column)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    width: 100%;
    gap: 16px;
    padding: 16px 16px 0;
  }
  width: 520px;
  gap: 32px;
`

const SubText = styled(BaseText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    white-space: pre-wrap;
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: ${(p) => p.theme.fontSize.default};
  }
  font-size: 20px;
  line-height: 1.5;
  color: ${(p) => p.theme.color.black4};
`

const HeadingText = styled(SemiBoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 12px;
  }
  font-size: 20px;
  color: ${(p) => p.theme.color.primary4};
`

const MainText = styled(BoldText)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 29px;
  }
  font-size: 40px;
  white-space: pre-wrap;
`

const FormContainer = styled(Column)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    align-items: flex-start;
  }
  @media ${(p) => p.theme.deviceSize.desktop} {
    padding-bottom: 60px;
  }
  align-items: center;
`

const Main = styled(Column)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    height: 600px;
  }
  height: 800px;
  align-items: center;
  background-color: ${(p) => p.theme.color.background};
`
